<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2 col-6">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Mill" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="brandModel" :items="brandLists" default="" item-value="brand_id" item-text="brand_id" label="Brand" clearable dense prepend-icon="mdi-label-outline"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="categoryGroupModel" :items="categoryGroupLists" default="" item-value="cat_group" item-text="cat_group" label="Category Group" @change="(event) => categoryGroupOnChange(event)" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="categoryModel" :items="categoryLists" default="" item-value="category_id" item-text="cat_desc" label="Category" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="machModel" :items="machLists" default="" item-value="mach_type" item-text="descr" label="Machine" clearable dense prepend-icon="mdi-engine-outline"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="materialModel" :items="materialLists" default="" item-value="jenis" item-text="jenis" label="Material" clearable dense prepend-icon="mdi-widgets"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="gradeModel" :items="gradeLists" default="" item-value="grade" item-text="grade" label="Quality" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="colorModel" :items="colorLists" default="" item-value="KodeWarna" item-text="NamaWarna" label="Color" clearable dense prepend-icon="mdi-set-right"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="addIDModel" :items="addIDLists" default="" item-value="add_id" item-text="descr" label="Add ID" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="torIDModel" :items="torIDLists" default="" item-value="tor_id" item-text="descr" label="Tor ID" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="thicknessModel" :items="thicknessLists" default="" item-value="Tebal" :item-text="item => item.Tebal + ' mm'" label="Thickness" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="widthModel" :items="widthLists" default="" item-value="Lebar" :item-text="item => item.Lebar + ' mm'" label="Width" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-text-field v-model="lengthModel" onkeypress='return event.charCode >= 48 && event.charCode <= 57' label="Length(m)" clearable dense prepend-icon="mdi-ruler"></v-text-field>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-text-field v-model="prodCodeModel" label="Product Code" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-text-field>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-text-field v-model="batchIDModel" label="Batch ID" clearable dense prepend-icon="mdi-barcode-scan"></v-text-field>
                            </div>
                            <div class="col-lg-6 text-right">
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                            <div class="col-lg-12">
                                <div class="row text-left badgeInfo">
                                    <div class="col-lg-3">
                                        <span class="badge rounded-pill bg-primary"><span class="mdi mdi-store"></span> Total Stock Value : IDR {{ sumStock ? sumStock : '-'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-12 cardResult">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div class="row">

                            <div class="col-lg-12">
                                <v-data-table :headers="headers" :items="itemLists" class="elevation-1" :search="searchItem" :loading="loadingDatatable" dense>
                                    <template v-slot:top>
                                        <v-toolbar flat color="white">
                                            <div class="d-flex w-25 col-lg-6">
                                                <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                            </div>
                                            <div class="col-lg-6 text-right">
                                                <vue-json-to-csv :json-data="itemLists" :csv-title="'Depo Stock Report'" >
                                                    <v-btn color="success" class="mr-1" small>
                                                        Export to CSV <i class="mdi mdi-export-variant" aria-hidden="true"></i>
                                                    </v-btn>
                                                </vue-json-to-csv>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                </v-data-table>
                            </div>

                        </div>
                    </div>
                </div>
                <br />
                <div class="card elevation-5">
                    <div class="card-body">
                        <div class="row">

                            <div class="col-lg-12">
                                <v-data-table :headers="headersDivision" :items="itemListsDivision" class="elevation-1" :loading="loadingDatatable" dense>
                                </v-data-table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

import VueJsonToCsv from 'vue-json-to-csv'

export default {
    components:{
        VueJsonToCsv
    },
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'E-Reports',
                disabled: false,
                href: '#',
                },
                {
                text: 'Depo Stock Value Report',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            brandModel: '',
            brandLists: [],
            categoryGroupModel: '',
            categoryGroupLists: [],
            categoryModel: '',
            categoryLists: [],
            machModel: '',
            machLists: [],
            materialModel: '',
            materialLists: [],
            colorModel: '',
            colorLists: [],
            addIDModel: '',
            addIDLists: [],
            torIDModel: '',
            torIDLists: [],
            gradeModel: '',
            gradeLists: [],
            thicknessModel: '',
            thicknessLists: [],
            widthModel: '',
            widthLists: [],
            lengthModel: '',
            prodCodeModel: '',
            batchIDModel: '',
            itemLists: [],
            headers: [
                { text: 'BRAND', value: 'brand_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DESC.', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'MATERIAL', value: 'Jenis', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PROD. CODE', value: 'BarangJadiId', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'THICK.(MM)', value: 'Tebal', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'LENGTH(M)', value: 'panjang', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'COLOR', value: 'NamaWarna', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'QTY.', value: 'stock', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WGT. STOCK(KG)', value: 'wgt_stock', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'RESERVE', value: 'reserve', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WGT. RESERVE(KG)', value: 'wgt_reserve', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'UNRELEASE', value: 'unrelease', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'AVAIL.', value: 'sAvailable', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WGT. AVAIL.(KG)', value: 'wgt_sAvailable', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ADD ID', value: 'add_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'TOR', value: 'tor_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'AVG. PRICE', value: 'avg_price', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'TOTAL VALUE', value: 'tot_value', align: 'right', class: 'primary--text blue lighten-5' },
            ],
            searchItem: '',
            loadingDatatable: false,
            detailModalTitle: '',
            itemListsDivision: [],
            headersDivision:[
                { text: 'CATEGORY', value: 'cat_desc', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'QTY', value: 'stock', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'TOTAL VALUE', value: 'totValue', align: 'right', class: 'primary--text blue lighten-5' },
            ],
            userGroup: null,
            appl_id: 'E-REPORTS',
            sumStock: ''
        }
    },
    async mounted(){
        
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardResult").style.display = "none";
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/DepoStockValueReport`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.millLists = res.data.mill
                this.brandLists = res.data.brand
                this.categoryGroupLists = res.data.category
                this.categoryLists = res.data.category
                this.machLists = res.data.mach
                this.materialLists = res.data.material
                this.colorLists = res.data.color
                this.addIDLists = res.data.add_id
                this.torIDLists = res.data.tor_id
                this.gradeLists = res.data.grade
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        async getData(){

            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        text: 'Please Select Mill',
                        icon: 'warning',
                    })
                return false
            }

            document.querySelector(".cardResult").style.display = "block";
            this.itemLists = []
            this.itemListsDivision = []
            this.loadingDatatable = true
            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/DepoStockValueReport/getData`, { 

                    mill: this.millModel ? this.millModel : '',
                    brand: this.brandModel ? this.brandModel : '',
                    category: this.categoryModel ? this.categoryModel : '',
                    mach: this.machModel ? this.machModel : '',
                    material: this.materialModel ? this.materialModel : '',
                    color: this.colorModel ? this.colorModel : '',
                    addID: this.addIDModel ? this.addIDModel : '',
                    torID: this.torIDModel ? this.torIDModel : '',
                    grade: this.gradeModel ? this.gradeModel : '',
                    thickness: this.thicknessModel ? this.thicknessModel : '',
                    width: this.widthModel ? this.widthModel : '',
                    length: this.lengthModel ? this.lengthModel : '',
                    prodCode: this.prodCodeModel ? this.prodCodeModel : '',
                    batchID: this.batchIDModel ? this.batchIDModel : ''
                    
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                if(res.data.result.length != 0){
                    document.querySelector(".cardResult").style.display = "block";
                    this.itemLists = res.data.result
                    this.sumStock = res.data.sumStock
                    this.itemListsDivision = res.data.resultDivision
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'warning',
                    })
                }
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        categoryGroupOnChange(id){

            if(id){

                this.categoryLists = []

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/InvoiceAnalysis/getCategoryByGroup?id=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.categoryLists = res.data.result

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {

                this.categoryLists = []

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/InvoiceAnalysis/getCategoryByGroup?id=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.categoryLists = res.data.result

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            }

        },

    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

</style>